const getPageName = (pathname, accountName) => {
  switch (pathname) {
    case "/myaccount/menu/profile":
      return "Profile";

    case "/myaccount/menu/addresses":
      return "Addresses";

    case "/myaccount/menu/contacts":
      return "Contacts";

    case "/myaccount/menu/quotes":
      return "Quotes";

    case "/myaccount/menu/salesorders":
      return "Sales Orders";

    case "/myaccount/menu/estimates":
      return "Estimates";

    case "/myaccount/menu/orderdetails":
      return "Sales Orders";

    case "/myaccount/menu/estimatedetails":
      return "Estimates";

    case "/myaccount/menu/reorder":
      return "Easy Reorder";

    case "/myaccount/menu/invoices":
      return "Invoices";

    case "/myaccount/menu/invoicedetails":
      return "Invoices";

    case "/myaccount/menu/payment/create":
      return "Make a Payment";

    case "/myaccount/menu/payment/method":
      return "Make a Payment";

    case "/myaccount/menu/payment/review":
      return "Make a Payment";

    case "/myaccount/menu/payment/confirmation":
        return "Make a Payment";

    case "/myaccount/menu/paymenthistory":
      return "Payment History";

    case "/myaccount/menu/paymentdetails":
      return "Payment History";

    case "/myaccount/menu/paymentmethod":
      return "Payment Methods";

    case "/myaccount/menu/security":
      return "Security";

    case "/myaccount/menu/itemlists":
      return "Item Lists";

    case "/myaccount/menu/openitems":
      return "Open Items";

    case "/myaccount/menu/approvals":
      return "Approve Orders";
    
      case "/myaccount/menu/creditmemos":
      return "Credit Memos";
    
    // case "/myaccount/menu/subscriptions":
    //   return "Subscriptions";

    case "/myaccount/menu/accountsettings":
      return "Settings";
    default:
      return `${accountName}`;
  }
}


const getPageDescription = (pathname) => {
  switch (pathname) {
    case "/myaccount/menu/profile":
      return "Set up your profile and basic information";

    case "/myaccount/menu/addresses":
      return "Manage your company’s addresses and Dealmed Delivery Day options";

    case "/myaccount/menu/contacts":
      return "Manage your company’s contacts and user permissions";

    case "/myaccount/menu/quotes":
      return "View your quotes";

    case "/myaccount/menu/salesorders":
      return "View your orders and related shipments and invoices. Easy options to reorder";

    case "/myaccount/menu/estimates":
      return "Estimates";

    case "/myaccount/menu/orderdetails":
      return "Sales Orders";

    case "/myaccount/menu/estimatedetails":
      return "Estimates";

    case "/myaccount/menu/reorder":
      return "Reorder recently ordered items";

    case "/myaccount/menu/invoices":
      return "View your invoices";

    case "/myaccount/menu/invoicedetails":
      return "Invoices";

    case "/myaccount/menu/payment/create":
        return "Select invoices you would like to pay";
  
    case "/myaccount/menu/payment/method":
        return "Select your desired payment methods";
  
    case "/myaccount/menu/payment/review":
        return "Review the payment";

    case "/myaccount/menu/payment/confirmation":
        return "Payment Confirmed";

    case "/myaccount/menu/paymenthistory":
      return "View your payment history";

    case "/myaccount/menu/paymentdetails":
      return "Payment History";

    case "/myaccount/menu/paymentmethod":
      return "Manage your payment methods";

    case "/myaccount/menu/security":
      return "Security";

    case "/myaccount/menu/itemlists":
      return "Curate lists of items for easy ordering";

    case "/myaccount/menu/openitems":
        return "Review and manage currently unfulfilled items";

    case "/myaccount/menu/approvals":
      return "Approve orders that have been placed by your company";

    case "/myaccount/menu/accountsettings":
      return "Review settings";

    case "/myaccount/menu/subscriptions":
      return "No Hassle Reordering!";
    
      case "/myaccount/menu/creditmemos":
      return "View your Credit Memos";

    default:
      return "Welcome to Dealmed Runtime™";
  }
}


export { getPageName, getPageDescription };
