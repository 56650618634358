import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import MyAccountHOC from '../Reusable/MyAccountHOC';
import InitializeHOC from '../Reusable/InitializeHOC';
import RelatedAccountsDropdown from '../Reusable/RelatedAccountsDropdown';
import { logout, switchAccount } from '../../actions';
import DealmedPopover from '../../shared/components/DealmedPopover';
import Spinner from '../../shared/components/Spinner';
import DownChevronSVG from '../../shared/icons/DownChevronSVG';
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent';

const accountPopoverSections = [
	{
		title: 'Purchases',
		links: [
			{ text: 'Easy Reorder', path: '/reorder' },
			{ text: 'My Lists', path: '/itemlists' },
			{
				text: 'Sales Orders',
				path: '/salesorders',
				permission: 'isPriceVisible',
			},
			{
				text: 'Open Items',
				path: '/openitems',
      			// permission: 'isPriceVisible' should we add this permission constraint ?
			},
			{ text: 'Approve Orders', path: '/approvals', permission: 'isAdmin' },
		],
	},
	{
		title: 'Billing',
		links: [
			{ text: 'Make Payment', path: '/payment/create', permission: 'isPriceVisible' },
			{ text: 'Invoices', path: '/invoices', permission: 'isPriceVisible' },
			{ text: 'Payment History', path: '/paymenthistory' },
			{ text: 'Payment Methods', path: '/paymentmethod' },
			{ text: 'Credit Memos', path: '/creditmemos' },
		],
	},
	{
		title: 'Company',
		links: [
			{ text: 'Profile', path: '/profile' },
			// { text: 'Dealmed Delivery Day', path: '/dealmeddeliveryday' },
			{ text: 'Addresses', path: '/addresses' },
			{ text: 'Contacts', path: '/contacts', permission: 'isAdmin' },
			{ text: 'Settings', path: '/accountsettings', permission: 'isAdmin' },
		],
	},
];

const MyAccountPopoverSection = ({ title, links, hasPermission, isOpen, closePopover, openPopover }) => {
	const sectionLinks = links
		.filter((link) => !link.permission || hasPermission(link.permission))
		.map((link, index) => (
			<li key={index} className="dealmed-header__account-section-item" onClick={() => isOpen ? closePopover() : openPopover()}>
				<Link to={`/myaccount/menu${link.path}`} className="dealmed-header__account-section-link">
					{link.text}
				</Link>
			</li>
		));

	return (
		<div className="dealmed-header__account-section">
			<h2 className="dealmed-header__account-section-title">{title}</h2>
			<ul className="dealmed-header__account-section-list">{sectionLinks}</ul>
		</div>
	);
};

const MyAccountPopover = (props) => {
	const { isOpen, openPopover, closePopover, className, auth, logout, retrievingRelatedAccounts = false, cleanupOnLogout, hasPermission } = props;

	const hasRelatedAccounts = pathOr([], ['relatedAccounts'], auth).length > 1;

	const triggerRef = useRef(null);
	const popoverWrapperRef = useRef(null);
	const popoverMatchWidthRef = useRef(null);

	const handleClick = () => {
		if (isOpen) {
			closePopover();
		} else {
			openPopover();
		}
	};

	const logClickEvent = ({ eventType }) => {
		mixpanelLogEvent({ 
		  eventType, 
		  userDetails:props?.auth?.user, 
		  metaData:{
			currentPage: props.location?.pathname
		  } 
		})
	  }

	const handleLogout = () => {
		logout();
		cleanupOnLogout();
	};

	const MyAccountPopoverSection = ({ title, links, hasPermission }) => {
		const sectionLinks = links
			.filter((link) => !link.permission || hasPermission(link.permission))
			.map((link, index) => (
				<li key={index} className="dealmed-header__account-section-item" 
				onClick={() => {
					logClickEvent({ eventType:`MyAccountPopoverSection_${link.text.split(" ").join("_")}_Clicked` })
					handleClick()
					}}>
					<Link to={`/myaccount/menu${link.path}`} className="dealmed-header__account-section-link">
						{link.text}
					</Link>
				</li>
			));
	
		return (
			<div className="dealmed-header__account-section">
				<h2 className="dealmed-header__account-section-title">{title}</h2>
				<ul className="dealmed-header__account-section-list">{sectionLinks}</ul>
			</div>
		);
	};

	useEffect(() => {
		import('@reach/popover').then((module) => {
			popoverMatchWidthRef.current = module.positionMatchWidth;
		});
	}, []);

	return (
		<React.Suspense fallback={<Spinner />}>
			<button type="button" ref={triggerRef} className="dealmed-header__account-toggle-btn" onClick={handleClick} style={{minWidth:'230px'}}>
				Dealmed Runtime&#8482; Account
				<span className="dealmed-header__icon-wrapper">
					<DownChevronSVG />
				</span>
			</button>

			{isOpen && popoverMatchWidthRef.current && (
				<DealmedPopover
					innerRef={popoverWrapperRef}
					targetRef={triggerRef}
					closePopover={closePopover}
					position={popoverMatchWidthRef.current}
					className={className}
				>
					<div className="dealmed-header__account-header">
						<div className="dealmed-header__account-actions">
							<Link to="/myaccount" className="dealmed-header__account-link" 
							onClick={() => {
								logClickEvent({ eventType:`MyAccountPopoverSection_Dealmed_Runtime_Account_Clicked` })
								handleClick()
							}}>
								Go to Dashboard
							</Link>
							<button type="button" className="dealmed-header__account-logout" onClick={() => {
								logClickEvent({ eventType:`MyAccountPopoverSection_Logout_Clicked` })
								handleLogout()
							}}>
								Logout
							</button>
						</div>

						{hasRelatedAccounts ? (
							<div className="dealmed-header__account-related">
								{retrievingRelatedAccounts ? <p>Loading related accounts...</p> : <RelatedAccountsDropdown isSearchable />}
							</div>
						) : null}
					</div>

					<div className="dealmed-header__account-sections">
						{accountPopoverSections.map((section, index) => {
							return (
                <MyAccountPopoverSection
				key={index}
                  title={section.title}
                  links={section.links}
                  hasPermission={hasPermission}
                  isOpen={isOpen}
                  openPopover={openPopover}
                  closePopover={closePopover}
                />
              )
						})}
					</div>
				</DealmedPopover>
			)}
		</React.Suspense>
	);
};



const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		retrievingRelatedAccounts: pathOr(false, ['loader', 'retrievingRelatedAccounts'], state),
	};
};

export default connect(mapStateToProps, { logout, switchAccount })(MyAccountHOC(InitializeHOC(MyAccountPopover)));